/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const url = typeof window !== 'undefined' ? window.location.origin : 'https://leopicado.com';

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {"name": "description", "content": `${metaDescription}`},
        {"name": "og:description", "content": `${metaDescription}`},
        {"name": "og:url", "content": `${url}`},
        {"name": "og:image", "content": `${url}/icons/icon-512x512.png`},
        {"name": "og:title", "content": `${title}`},
        {"name": "twitter:card", "content": "summary"},
        {"name": "twitter:creator", "content": "@leopyc"},
        {"name": "twitter:site", "content": "@leopyc"},
        {"name": "twitter:title", "content": `${title}`},
        {"name": "twitter:description", "content": `${metaDescription}`},
        {"name": "twitter:image", "content": `${url}/icons/icon-512x512.png`},
        {"name": "twitter:image:alt", "content": "Photo of Leo Picado"},
        ]}
    >
      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "Person",
          "name": "Leo Picado",
          "jobTitle": "Software Engineer",
          "description": "Wearer of many hats, former designer, full-stack developer, teacher. Now: iOS Developer.",
          "image": "${url}/icons/icon-512x512.png",
          "url": "${url}"
        }
      `}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO
