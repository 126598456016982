import React from "react"

import "./footer.scss"
import {Link} from "gatsby";

export default () => {
  return (
    <footer>
      <nav className="sm-wrap">
        <a itemProp="url" className="sm-item linkedin js-linkedin" href="http://www.linkedin.com/in/leopic"
           target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a itemProp="url" className="sm-item github js-github" href="https://github.com/leopic"
           target="_blank" rel="noopener noreferrer">Github</a>
        <a itemProp="url" className="sm-item twitter js-twitter" href="http://twitter.com/leopyc"
           target="_blank" rel="noopener noreferrer">@leopyc</a>
        <a itemProp="url" className="sm-item stackoverflow" href="http://stackoverflow.com/users/622356/leopic"
           target="_blank" rel="noopener noreferrer">StackOverflow</a>
        <a itemProp="url" className="sm-item instagram" href="http://instagram.com/leopic"
           target="_blank" rel="noopener noreferrer">Instagram</a>
      </nav>

      <small style={{margin: '1rem 0 .25rem', display: 'block'}}>
        Cartago, Costa Rica. {new Date().getFullYear()} - Leo Picado.
      </small>

      <ul style={{margin: '0', fontSize: '10px', padding: '0', listStyleType: 'none'}}>
        <li style={{display: 'inline-block', margin: '0 .5rem 0 0'}}><Link to={'misc/emojiply/privacy-policy'}>emojiply - Privacy Policy</Link></li>
        <li style={{display: 'inline-block'}}><Link to={'misc/emojiply/politica-de-privacidad'}>emojiply - Política de privacidad</Link></li>
      </ul>
    </footer>
  )
};
