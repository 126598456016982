import React from "react"
import { Link } from "gatsby"

import '../components/home-link.scss'

const HomeLink = () => (
  <Link to="/" className="home-link">&lt; Home</Link>
);

export default HomeLink
