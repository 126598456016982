import { Link } from "gatsby"
import React from "react"

import "./header.scss"

const Header = () => (
  <header>
    <Link to="/">
      <h1 className="name-text">Leo<strong> Picado</strong></h1>
      <h2 className="position-text">Software Engineer</h2>
    </Link>
  </header>
);

export default Header
